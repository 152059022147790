import { useState } from "react";
import LoginImage from "../../assets/LoginImage.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from "../../assets/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../../Context/AppContext";
import Spinner from "../../Components/spinner";
import { FiAlertCircle } from "react-icons/fi";
import Logo from "../../Components/Logo";

function ErrorMessage({ error }: { error: string }) {
  return <div className="flex justify-start py-2 text-red-500">{error}</div>;
}

const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  // const [loading, setLoading] = useState<boolean>(true);
  const { sendRequest } = useAppContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { handleSubmit, handleChange, errors, touched, } = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: SignInSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      // use sendRequest from the context
      sendRequest("POST", "/api/v1/login", values)
        .then(
          (response: {
            data: {
              message: string;
              data: {
                access_token: string;
                refresh_token: string;
                user: any
              }
            };
          }) => {
            // store the token/refresh_token/user in the localstorage
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("Refresh-token", response.data.data.refresh_token);

            //This isnt the best way to store user data, but for now it will do
            localStorage.setItem("user", JSON.stringify(response.data.data.user));


            if (response.data.message === "login successful") {
              // confirm if this reloads the page,
              // we want it to reload so that the context
              // will pick up the token from the localstorage
              navigate("/dashboard");
              window.location.reload()
            }
          }
        )
        .catch((error: any) => {
          console.log(error);
          setError("Invalid email or password");
        }).finally(() => { setLoading(false) });
    },
  });

  return (
    <>
      {/* {loading && <SplashScreen />} */}
      {/* <div style={{ display: loading ? "none" : "block" }}> */}
      <div>
        <div className="lg:flex ">
          <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto gap-y-10">
            <div className="lg:mx-16 md:mx-16 mx-12 pt-10 items-center flex justify-between">
              <Logo />
            </div>

            <div className="flex flex-1 flex-col ">
              <div className="font-sans lg:px-16 md:px-16 px-12 pb-10">
                <h3 className="text-4xl pb-2 text-atlas_black dark:text-white">Sign In</h3>
                <p className="font-normal text-opacity-70 text-sm text-slate-600 dark:text-atlas_darkslate">
                  Enter the following information correctly to create your Atlax
                  account
                </p>
              </div>

              <form
                className="lg:px-16 md:px-16 px-12 font-manrope"
                onSubmit={handleSubmit}
              >
                <div>{error ? <ErrorMessage error={error} /> : ""}</div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="text-atlas_black dark:text-white font-normal text-base block"
                  >
                    Email
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="email"
                      name="email"
                      className={`${errors.email ? 'border-red-600' : 'border-none'} px-3 py-3 w-full text-black dark:text-white rounded-md bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                      onChange={handleChange}
                      required
                      placeholder="someone@email.com"
                      inputMode="email"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    >
                      {errors.email ? (
                        <FiAlertCircle className="text-red-600" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-sm">
                    {errors.email ? (
                      <ErrorMessage error={errors.email.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <label
                  htmlFor="password"
                  className="text-atlas_black dark:text-white font-normal text-base block"
                >
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={show ? "text" : "password"}
                    name="password"
                    className={`${errors.email ? 'border-red-600' : 'border-none'} px-3 py-3 w-full text-black dark:text-white rounded-md bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    onChange={handleChange}
                    required
                    placeholder="Password"
                  />
                  <div className="flex space-x-5 items-center">
                    <div
                      className="absolute inset-y-0 right-0 pr-9 flex items-center cursor-pointer"
                    >
                      {errors.email ? (
                        <FiAlertCircle className="text-red-600" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
                      ) : (
                        <AiOutlineEye className="text-black dark:text-white" color="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-end">
                  <div className="text-sm">
                    {errors.password ? (
                      <ErrorMessage error={errors.password.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex justify-end py-2">
                    <Link to="/reset-password">
                      <button className=" italic text-xs text-atlas_orange text-opacity-90 hover:brightness-90">
                        forgot password?
                      </button>
                    </Link>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className={`${loading ? 'bg-atlas_disabled_orange' : 'bg-atlas_orange'} btn py-3 mt-5 mb-3`}
                >
                  {loading ? <Spinner /> : "Sign in"}
                </button>
                <div className="flex space-x-2 items-center place-content-center italic text-sm">
                  <p className="text-atlas_gray dark:text-atlas_grey cursor-default opacity-85">Don't have an Atlax account?</p>
                  <Link to="/signup" className="font-normal cursor-pointer text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline">
                    Sign Up
                  </Link>
                </div>
              </form>
            </div>
          </div>

          <div className="hidden lg:block bg-atlas_dark flex-1">
            <div className="flex justify-center items-center h-full">
              <img className='h-screen w-full object-cover' alt='side' src={LoginImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
