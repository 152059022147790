import { BiLogoGmail } from "react-icons/bi";
import { FaPhoneAlt, FaTelegram } from "react-icons/fa";
import {
  FaLinkedinIn,
  FaWhatsapp,
  FaXTwitter,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/Logo.svg";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="">
      <div className="lg:flex md:flex block justify-around bg-atlas_gray pt-6 pb-24 pl-10 font-manrope">
        <HashLink smooth to='/#home'>
         <div className="py-5 lg:py-0 md:py-0">
          <img className="w-12 h-12 " src={Logo} alt="logo" />
        </div>
        </HashLink>
       

        <div className="py-5 lg:py-0 md:py-0">
          <HashLink
                  smooth to="/#services"
                  className="hover:text-atlas_orange pb-4 text-white text-lg"
                >
                  Our Services
                </HashLink>

          <ul className="text-atlas_offgrey text-xs   cursor-pointer">
              <li className="hover:text-atlas_orange py-4">
                <HashLink smooth to="/#about-us">About Us</HashLink>
              </li>
              <li className="hover:text-atlas_orange py-4">
                <HashLink smooth to="/#contact-us">Contact Us</HashLink>
              </li>
              <li className="hover:text-atlas_orange py-4">
                <HashLink smooth to="/#faq">FAQ</HashLink>
              </li>
          </ul>
        </div>

        <div className="w-3/4 lg:w-1/4 md:w-1/4 lg:py-0 md:py-0 py-5">
          <p className="pb-4 text-white text-base">About Us</p>
          <p className="text-atlas_offgrey text-xs font-normal">
            Redefine Your Financial Experience with Atlax Exchange. Your TRUSTED
            platform for Treasury Management, International transfers, payments,
            lending, and investments.
          </p>
        </div>

        <div className="py-5 lg:py-0 md:py-0">
          <p className="pb-4 text-white text-sm">Contact Us</p>
          <div className="flex gap-5 cursor-pointer">
            <div className="bg-white rounded-full p-2">
              <Link to="mailto:hi@atlaxchange.com">
                <BiLogoGmail size={12} />
              </Link>
            </div>
            <div className="bg-white rounded-full p-2">
              {" "}
              <Link to="tel:+2348022841012">
                <FaPhoneAlt size={12} />
              </Link>
            </div>
            <div className="bg-white rounded-full p-2">
              <Link to="https://wa.me/message/TDVMTPQUB4PHI1">
                <FaWhatsapp size={12} />
              </Link>
            </div>

            <div className="bg-white rounded-full p-2">
              {" "}
              <Link to="https://www.linkedin.com/company/atlax-exchange/ ">
                <FaLinkedinIn size={12} />
              </Link>
            </div>
            <div className="bg-white rounded-full p-2">
              {" "}
              <Link to="https://t.me/atlaxexchange">
                <FaTelegram size={12} />
              </Link>
            </div>
            <div className="bg-white rounded-full p-2">
              {" "}
              <Link to="https://x.com/Atlaxchange?t=2v29AglyqlE61Jj7bNyhlg&s=09">
                <FaXTwitter size={12} />
              </Link>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
